<template>
   <ObiText class="obi-tb" v-bind="attrs" v-on="$listeners">
      <div class="obi-tb-top">
         <div class="obi-tb-tabs" v-if="$slots.tabs">
            <slot name="tabs" />
         </div>

         <div class="obi-tb-actions" v-if="$slots.actions">
            <slot name="actions" />
         </div>
      </div>

      <div class="obi-tb-body" :class="{ 'p-0': noPadding }" v-if="$slots.default">
         <slot />
      </div>
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';

export default {
   name: 'ObiTabBar',
   props: {
      as: {
         type: [String, Object],
         default: 'div',
      },
      noPadding: {
         type: [Boolean],
         default: false,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-tb {
   .obi-tb-top {
      display: grid;
      padding: 0 3.5rem;
      grid-template-columns: 1fr auto;
      border-bottom: 1px solid #ededed;
   }

   .obi-tb-tabs {
      padding-top: 0.75rem;

      > .obi-tabs {
         bottom: -1px;
         position: relative;
      }
   }

   .obi-tb-actions {
      padding-top: 1rem;
      padding-bottom: 1rem;
   }

   .obi-tb-body {
      padding: 2.5rem 3.5rem;
   }

   &.--for-portal-editor {
      .obi-tb-top {
         padding: 0 2rem;
      }
   }
}
</style>
